import axios from 'axios'
import env from 'settings/env'
import { get, clear } from 'services/storage'
import routes from 'routes'
import { useDispatch } from 'react-redux'
import { setToken } from 'store/modules/Steps.store'
import { toast } from 'react-toastify'

import * as Sentry from '@sentry/react'

/*
const headers = {
  Authorization: 'Basic cGV0ejpwZXR6MTAxMA==',
  'Content-Type': 'application/json',
  'X-Authorization': `Bearer ${access_token}`,
  Accept: 'application/json'
}*/

export const api = axios.create({
  baseURL: env.API_LEITURINHA_CHECKOUT_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// api.interceptors.request.use(
//   (config) => {
//     config.headers = {
//       Authorization: `Bearer ${get<string>('token')}`
//     }

//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      Sentry.captureException(error.response.data)
    }
    if (error.response.status === 401) {
      // Limpar somente o token
      // Madar para o Cadastro

      window.location.href = routes.resetToken
      return
    } else {
      Sentry.captureException(error.message)
    }

    Sentry.captureException(error)

    return Promise.reject(error)
  }
)

export default api
