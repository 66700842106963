import routes from 'routes'
import { v4 as uuidv4 } from 'uuid'

import ReactPixel from 'react-facebook-pixel'

export function initializePixel(themeId: string) {
  ReactPixel.init(themeId)
}

export function pixelPageView(location) {
  ReactPixel.pageView()
}

export function sendPixelEvent(name: string, id: string) {
  switch (name) {
    case 'InitiateCheckout':
      ReactPixel.track('InitiateCheckout', {
        eventID: id
      })
      break
    case 'ChildrenRegister':
      ReactPixel.trackCustom('ChildrenRegister', {
        eventID: id
      })
      break
    case 'UserRegister':
      ReactPixel.trackCustom('UserRegister', {
        eventID: id
      })
      break
    case 'AddressRegister':
      ReactPixel.trackCustom('AddressRegister', {
        eventID: id
      })
      break
    case 'AddPaymentInfo':
      ReactPixel.track('AddPaymentInfo', {
        eventID: id
      })
      break
    default:
      ReactPixel.pageView()
      break
  }
}

export function sendPixelEventPurchase(id: string, value: number) {
  ReactPixel.fbq('track', 'Purchase', {
    eventID: id,
    currency: 'BRL',
    value: value
  })
}

export function facebookConversionData(themeId: string, total: number) {
  ReactPixel.fbq('track', 'Purchase', {
    eventID: `${uuidv4()}`,
    currency: 'BRL',
    value: total
  })
}

export function getFbc(clickId: string) {
  // version.subdomainIndex.creationTime.<fbclid>
  const version = 'fb'
  const subdomainIndex = 1
  const creationTime = new Date().getTime()
  const fbclid = clickId

  return `${version}.${subdomainIndex}.${creationTime}.${fbclid}`
}

export function getFbp() {
  // version.subdomainIndex.creationTime.<fbclid>
  const version = 'fb'
  const subdomainIndex = 1
  const creationTime = new Date().getTime()
  const random = String(new Date().getTime()).slice(0, 10)

  return `${version}.${subdomainIndex}.${creationTime}.${random}`
}
