export const imageList = [
  {
    name: 'logoImage',
    url: '/logo.png'
  },
  {
    name: 'select',
    url: '/select.svg'
  },
  {
    name: 'plusCircle',
    url: '/plusCircle.svg'
  },
  {
    name: 'minusCircle',
    url: '/minusCircle.svg'
  },
  {
    name: 'mascoteImage',
    url: '/modal/mascote.png'
  },
  {
    name: 'mascotePen',
    url: '/modal/mascotePen.svg'
  },
  {
    name: 'mascoteFly',
    url: '/modal/mascoteFly.svg'
  },
  {
    name: 'mascoteHi',
    url: '/modal/mascoteHi.svg'
  },
  {
    name: 'charactersImage',
    url: '/characters.png'
  },
  {
    name: 'cupomImage',
    url: '/cupom.png'
  },
  {
    name: 'cupomSvg',
    url: '/cupom.png'
  },
  {
    name: 'checkImage',
    url: '/checkcheck.svg'
  },
  {
    name: 'bebelImage',
    url: '/bebelAvatar.png'
  },
  {
    name: 'edit',
    url: '/edit.png'
  },
  {
    name: 'avatarBebelRed',
    url: '/avatar/bebelAvatarRed.svg'
  },
  {
    name: 'avatarBebelRedLarge',
    url: '/avatar/bebelAvatarRedLarge.svg'
  },
  {
    name: 'avatarBebelGreen',
    url: '/avatar/bebelAvatarGreen.svg'
  },
  {
    name: 'avatarBebelPurple',
    url: '/avatar/bebelAvatarPurple.svg'
  },
  {
    name: 'shieldPurple',
    url: '/shield/shieldPurple.svg'
  },
  {
    name: 'shieldGreen',
    url: '/shield/shieldGreen.svg'
  },
  {
    name: 'kitImage',
    url: '/kit.png'
  },
  {
    name: 'kitFlapImage',
    url: '/kitFlap.png'
  },
  {
    name: 'basicTitle',
    url: '/titleCards/basicTitle.png'
  },
  {
    name: 'benefitTitle',
    url: '/titleCards/benefitTitle.png'
  },
  {
    name: 'recomendedTitle',
    url: '/titleCards/recomendedTitle.png'
  },
  {
    name: 'breadPlanos',
    url: '/breadcrumb/breadPlanos.svg'
  },
  {
    name: 'vectorProgress',
    url: '/breadcrumb/vectorProgress.svg'
  },
  {
    name: 'breadCrianca',
    url: '/breadcrumb/breadCrianca.svg'
  },
  {
    name: 'vectorProgressOff',
    url: '/breadcrumb/vectorProgressOff.svg'
  },
  {
    name: 'breadAdulto',
    url: '/breadcrumb/breadAdulto.svg'
  },
  {
    name: 'breadAdultoOff',
    url: '/breadcrumb/breadAdultoOff.svg'
  },
  {
    name: 'breadEndereco',
    url: '/breadcrumb/breadEndereco.svg'
  },
  {
    name: 'breadEnderecoOff',
    url: '/breadcrumb/breadEnderecoOff.svg'
  },
  {
    name: 'checkPink',
    url: '/check/checkPink.svg'
  },
  {
    name: 'checkBlue',
    url: '/check/checkBlue.svg'
  },
  {
    name: 'checkPurple',
    url: '/check/checkPurple.svg'
  },
  {
    name: 'unchecked',
    url: '/check/unchecked.svg'
  },
  {
    name: 'googleIcon',
    url: '/auth/googleIcon.svg'
  },
  {
    name: 'facebookIcon',
    url: '/auth/facebookIcon.svg'
  },
  {
    name: 'bgblur',
    url: '/blur.jpg'
  },
  {
    name: 'bgBlurLight',
    url: '/blurLight.jpg'
  },
  {
    name: 'bgBlurMobile',
    url: '/blurMobile.png'
  },
  {
    name: 'leitorDeBerco',
    url: '/categories/a0.png'
  },
  {
    name: 'leitorBrincante',
    url: '/categories/a1.png'
  },
  {
    name: 'leitorCritico',
    url: '/categories/a2.png'
  },
  {
    name: 'leitorProcesso',
    url: '/categories/a3.png'
  },
  {
    name: 'preLeitor',
    url: '/categories/a4.png'
  },
  {
    name: 'LeitorFluente',
    url: '/categories/a5.png'
  },
  {
    name: 'leitorIniciante',
    url: '/categories/a6.png'
  },
  {
    name: 'QRCode',
    url: '/payment/QRCode.png'
  },
  {
    name: 'bgModal',
    url: '/bgModal.png'
  },
  {
    name: 'iconStepOk',
    url: '/steps/step_ok.svg'
  },
  {
    name: 'iconStep1CriancaActive',
    url: '/steps/step1_planos_active.svg'
  },
  {
    name: 'iconStep2CriancaActive',
    url: '/steps/step2_crianca_active.svg'
  },
  {
    name: 'iconStep2CriancaDisabled',
    url: '/steps/step2_crianca_disabled.svg'
  },
  {
    name: 'iconStep3CriancaActive',
    url: '/steps/step3_adulto_active.svg'
  },
  {
    name: 'iconStep3CriancaDisabled',
    url: '/steps/step3_adulto_disabled.svg'
  },
  {
    name: 'iconStep4CriancaActive',
    url: '/steps/step4_endereco_active.svg'
  },
  {
    name: 'iconStep4CriancaDisabled',
    url: '/steps/step4_endereco_disabled.svg'
  },
  {
    name: 'iconStepsDotsActive',
    url: '/steps/steps_dots_active.svg'
  },
  {
    name: 'iconStepsDotsDisabled',
    url: '/steps/steps_dots_disabled.svg'
  },
  {
    name: 'imgPlansMini',
    url: '/plans/mini.webp'
  },
  {
    name: 'imgPlansUni',
    url: '/plans/uni.webp'
  },
  {
    name: 'imgPlansDuni',
    url: '/plans/duni.webp'
  },
  {
    name: 'imgPlansFlap',
    url: '/plans/flap.webp'
  },
  {
    name: 'logoFlap',
    url: '/logo/logoFlap.svg'
  },
  {
    name: 'logoLeiturinha',
    url: '/logo/logoLeiturinha.svg'
  },
  {
    name: 'logoplaykids',
    url: '/logo/logoPlaykids.png'
  },
  {
    name: 'bannerLeiturinha',
    url: '/banner/leiturinha.png'
  },
  {
    name: 'bannerFlap',
    url: '/banner/flap.svg'
  },
  {
    name: 'avatarFlap',
    url: '/avatar/flapAvatar.png'
  },
  {
    name: 'avatarFlapPurchase',
    url: '/avatar/flapAvatar.png'
  },
  {
    name: 'loadingFlap',
    url: '/loading/flapLooping.svg'
  },
  {
    name: 'loadingLeiturinha',
    url: '/loading/bebelLooping.png'
  },
  {
    name: 'mascoteFlap',
    url: '/modal/mascoteFlap.svg'
  },
  {
    name: 'leituverso',
    url: '/orderbump/leituverso.png'
  },
  {
    name: 'add',
    url: '/orderbump/add.svg'
  },
  {
    name: 'UNI',
    url: '/plans/uni-arrow.png'
  },
  {
    name: 'DUNI',
    url: '/plans/duni-arrow.png'
  },
  {
    name: 'bumpPromo',
    url: '/orderbump/promo.png'
  },
  {
    name: 'checkSelection',
    url: '/orderbump/check.png'
  },
  {
    name: 'learnMain',
    url: '/orderbump/learn.png'
  },
  {
    name: 'learnSmall',
    url: '/orderbump/small-learn.png'
  },
  {
    name: 'addBlue',
    url: '/orderbump/add-blue.png'
  },
  {
    name: 'modalBf',
    url: '/orderbump/modal-bf.png'
  },
  {
    name: 'bumpBf',
    url: '/orderbump/bump-bf.png'
  }
]
