import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { selectKids } from 'store/modules/Kids.store'
import { selectAddress } from 'store/modules/Address.store'
import { selectPayment } from 'store/modules/Payment.store'
import { selectTheme } from 'store/modules/Theme.store'
import { setLoading, selectSteps } from 'store/modules/Steps.store'
import { toKidsFormatedString } from 'utils/format'
import { getImage } from 'utils/images'

import { CheckCheck } from 'components/Icon'
import { Banner } from 'components/Banner/Banner'
import { SummaryOrder } from 'components/SummaryOrder'
import { selectPlans } from 'store/modules/Plans.store'
import { PlanType } from 'types/enums'
import { useCreditCard } from 'hooks/useCreditCard'
import { BrandFlagCardType, getCreditCardBrandFlag } from 'utils/payments'
import { selectDiscount } from 'store/modules/Discount.store'
import { formatToBRL } from 'brazilian-values'
import env from 'settings/env'
import { googleConversionData } from 'services/events/uga'
import { ga4ConversionData } from 'services/events/ga4'

const ENVIRONMENT = env.ENVIRONMENT

declare global {
  interface Window {
    google_conversion_id: number | null
    google_conversion_language: string
    google_conversion_format: string
    google_conversion_color: string
    google_conversion_label: string
    google_remarketing_only: boolean
  }
}

export default function PurchasePending() {
  const dispatch = useAppDispatch()
  const { model } = useAppSelector(selectKids)
  const { payment } = useAppSelector(selectPayment)
  const { planSelectedDetails } = useAppSelector(selectPlans)
  const { theme } = useAppSelector(selectTheme)
  const { discountResponse } = useAppSelector(selectDiscount)
  const { deliveryAddress } = useAppSelector(selectAddress)
  const { orderId } = useAppSelector(selectSteps)
  const { getCreditCardFlag } = useCreditCard()

  const finalCard = payment.card_number?.substring(
    payment.card_number?.length - 4
  )

  const selectedBrandFlag = getCreditCardBrandFlag(
    payment.card_number?.replace(' ', '') as string
  )

  const { periodicity } = planSelectedDetails

  const discount =
    (discountResponse?.plan_discount ?? 0) +
    (discountResponse?.shipping_discount ?? 0)
  const total =
    planSelectedDetails.monthly_price +
    planSelectedDetails.shipping_price -
    discount
  const totalShipping =
    planSelectedDetails.shipping_price -
    (discountResponse?.shipping_discount ?? 0)

  const gaId =
    theme.tracking.ga !== null ? theme.tracking.ga : theme.tracking.ga4
  const adsId =
    typeof theme.tracking.ads?.id === 'number' ? theme.tracking.ads.id : 0
  const adsLabel =
    typeof theme.tracking.ads?.label === 'string'
      ? theme.tracking.ads?.label
      : ''
  const pixelId =
    typeof theme.tracking.pixel === 'string' ? theme.tracking.pixel : ''

  useEffect(() => {
    dispatch(setLoading({ status: false, message: undefined }))

    if (ENVIRONMENT === 'production') {
      if (gaId !== undefined && gaId !== null) {
        googleConversionData(
          gaId,
          adsId,
          adsLabel,
          orderId,
          total,
          totalShipping,
          planSelectedDetails.slug ?? '',
          planSelectedDetails.name ?? ''
        )
      }
      if (gaId !== undefined && gaId !== null) {
        ga4ConversionData(
          gaId,
          adsId,
          adsLabel,
          orderId,
          total,
          totalShipping,
          planSelectedDetails.slug,
          planSelectedDetails.name
        )
      }
    }
  }, [])

  return (
    <>
      <div className="flex justify-center gap-5 bg-gray-100 px-8 pb-8">
        <div id="form" className="w-[32.5rem]">
          <div className="mb-2 flex items-center justify-start">
            <div className="mr-2">
              <img
                src={getImage(theme.character.img.default)}
                alt={`${theme.character.name} Online`}
              />
            </div>
            <div>
              <p className="font-bold text-purple">{theme.character.name}</p>
              <p className="text-xs font-bold text-purpleTransparent">Online</p>
            </div>
          </div>

          <div className="relative mb-4 rounded-r-lg rounded-b-lg border border-lightGrey bg-purple py-2 pl-4 pr-8 text-white">
            <p className="font-bold">Pedido em análise</p>
            <div className="absolute bottom-3 right-3">
              <CheckCheck />
            </div>
          </div>
          <div className="relative mb-4 rounded-r-lg rounded-b-lg border border-lightGrey bg-backgroundGrey py-2 pl-4 pr-8">
            <p className="mb-2 text-sm">
              {`Registramos seu pedido! Logo você receberá mais informações sobre sua assinatura.`}
            </p>
            <div className="absolute bottom-3 right-3">
              <CheckCheck color="#00a3ff" />
            </div>
          </div>
          <div className="relative mb-4 rounded-lg border border-lightGrey bg-white py-2 pl-4 pr-8">
            <p className="text-base font-bold">Resumo do pedido</p>
            <SummaryOrder
              name={planSelectedDetails.name}
              plan={planSelectedDetails.type as PlanType}
            />
            <div className="flex flex-col justify-between gap-1">
              <p className="text-base">{toKidsFormatedString(model.kids)}</p>

              <hr className="border-backgroundGrey" />
              <div className="flex items-center justify-between gap-1">
                <div className="flex items-center justify-between gap-1">
                  <p className="text-base">
                    XXXX-XXXX-XXXX-
                    {finalCard}
                  </p>
                  <div className="flex w-[20px]">
                    {getCreditCardFlag({
                      brandFlag: selectedBrandFlag as BrandFlagCardType
                    })}
                  </div>
                </div>
                <p className="text-base">{`${periodicity}x ${formatToBRL(
                  total
                )}`}</p>
              </div>

              <hr className="border-backgroundGrey" />
              <p className="text-base">
                {deliveryAddress.street}, {deliveryAddress.number} -{' '}
                {deliveryAddress.neighborhood} - {deliveryAddress.city} -{' '}
                {deliveryAddress.state}
              </p>
            </div>
          </div>
          <div className="relative mb-4 rounded-r-lg rounded-b-lg border border-lightGrey bg-backgroundGrey py-2 pl-4 pr-8">
            <p className="mb-2">{theme.success.app.text}</p>
            <a
              href={theme.success.app.link}
              title={theme.success.app.button}
              type="button"
              className="flex items-center justify-center rounded-lg bg-green py-2 px-14 font-medium text-white"
            >
              {theme.success.app.button}
            </a>
            <div className="absolute bottom-3 right-3">
              <CheckCheck color="#00a3ff" />
            </div>
          </div>
        </div>
        <Banner />
      </div>
    </>
  )
}
