import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

type LogState = {
  url: string | null
}

const initialState: LogState = {
  url: null
}

const logs = createSlice({
  name: 'logs',
  initialState: initialState,
  reducers: {
    setUrl(state, action) {
      state.url = action.payload
    },
    reset: () => initialState
  }
})

export const { setUrl, reset } = logs.actions

export const selectLog = (state: RootState) => state.logs

export default logs.reducer
